class CPAggregator {
    constructor(contents, key) {
        this.list = this.extract(contents, key)
        this.list = this.sort(this.list)
    }

    extract(contents, key) {
        let result = []
        contents.map((content) => {
            const findItem = result.find((item) => item.id == content[key].id)
            if (findItem) {
                findItem.count += 1
            } else {
                result.push({ "id": content[key].id, "name": content[key].name, "count": 1 })
            }
        })
        return result
    }

    sort(unsortedList) {
        return unsortedList.sort((a, b) => b.count - a.count)
    }
}

export default CPAggregator