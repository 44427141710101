import Image from "next/image";
import Link from "next/link";
import CPAggregator from "@/models/CPAggregator";
import imageList from "../../data/images.json";

// import CourseDetails from "../../data/course-details/courseData.json";
// import useCategoryCount from "@/context/useCategoryCount";

const CategoryOne = (courseDetails) => {
  courseDetails = courseDetails.courseDetails
  // const { categoryCounts } = useCategoryCount(CourseDetails.courseDetails);
  // const { categoryCounts } = useCategoryCount(courseDetails);

  // extract list of Categories from the Event list (including count) then sort based on count-desc
  const categoryAggregator = new CPAggregator(courseDetails, "category")
  const categories = categoryAggregator.list
  const catIcons = imageList.courseCategories

  // fill Icon images for all categories to display on HOME page
  categories.map(cat => {
    cat['icon'] = catIcons.find(icon => icon.id == cat.id).icon_large
  })

  // console.log(categories)

  return (
    <>
      {categories &&
        categories.map((item, innerIndex) => {
          // const count = item.count;
          return (
            <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={innerIndex}>
              <Link
                className="rbt-cat-box rbt-cat-box-1 text-center"
                href={`/courses?category=${item.id}`}
              >
                <div className="inner">
                  <div className="icons">
                    <Image
                      src={item.icon}
                      width={80}
                      height={80}
                      priority
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">{item.name}</h5>
                    <div className="read-more-btn">
                      <span className="rbt-btn-link">
                        {item.count} Course{item.count !== 1 ? "s" : ""}
                        <i className="feather-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </>
  );
};

export default CategoryOne;
