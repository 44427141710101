import PageHead from "../Head";
import HeaderStyleTen from "@/components/Header/HeaderStyle-Ten";
import Separator from "@/components/Common/Separator";
import MainDemo from "@/components/01-Main-Demo/01-Main-Demo";
import MobileMenu from "@/components/Header/MobileMenu";
import Cart from "@/components/Header/Offcanvas/Cart";

import Context from "@/context/Context";
import { Provider } from "react-redux";
import Store from "@/redux/store";
import FooterThree from "@/components/Footer/Footer-Three";
import CPStaticContent from '../../models/CPStaticContent.js'

const Home = (data) => {
  if(data.data){
    data = data.data
  }
  return (
    <>
      <PageHead title="Learn AI/ML, Cloud Computing, DevSecOps & Cybersecurity" />

      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderStyleTen headerSticky="rbt-sticky" headerType="" />
          <MainDemo data={data} />
          <Cart />

          <Separator />
          <FooterThree />
        </Context>
      </Provider>
    </>
  );
};

export default Home;

export async function getStaticProps(context) {
  const tfContent = new CPStaticContent("tfmdocs")
  const allModules = await tfContent.fetchContent()

  const csContent = new CPStaticContent("courses")
  const allCourses = await csContent.fetchContent()

  const evContent = new CPStaticContent("events")
  const allEvents = await evContent.fetchContent("published")
  console.log(allEvents)

  return { props: { tfModules: allModules, courses: allCourses, events: allEvents } }
} 