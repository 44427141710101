const PriceLabel = ({ post }) => {

  return (
    <>
      {post.price && post.price > 0 &&
        <div className="rbt-price">
          <span className="current-price">${post.price}</span>
          {post.offPrice && post.offPrice > 0 &&
            <span className="off-price">${post.offPrice}</span>
          }
        </div>
      }
      {post.price && post.price <= 0 &&
        <div className="rbt-price">
          <span className="current-price">FREE</span>
        </div>
      }
    </>
  );
};

export default PriceLabel;