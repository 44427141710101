import Image from "next/image";
import Link from "next/link";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Pagination } from "swiper/modules";
import PriceLabel from "@/components/Labels/PriceLabel";

// import MainDemoData from "../../../data/courses.json";

const HomeCourses = ({ start, end, courseList }) => {

  // console.log(courseDetails)
  // courseDetails = MainDemoData.courseDetails
  const courseDetails = courseList.allCourses
  // console.log(courseDetails)

  return (
    <>
      <Swiper
        className="swiper-wrapper"
        effect={"cards"}
        modules={[EffectCards, Pagination]}
        grabCursor={true}
        pagination={{
          el: ".rbt-swiper-pagination",
          clickable: true,
        }}
      >
        {courseDetails &&
          courseDetails.slice(start, end).map((data, index) => (
            <SwiperSlide className="swiper-slide" key={index}>
              <div className="rbt-card variation-01 rbt-hover">
                <div className="rbt-card-img">
                  <Link href={`/course/${data.content_id}`}>
                    <Image
                      src={data.image.poster}
                      width={710}
                      height={488}
                      alt="Card image"
                    />
                    <div className="rbt-badge-3 bg-white">
                      {(data.price && data.price <= 0) &&
                          <span>FREE</span>
                        }
                      {(data.price && data.price > 0) &&
                        <>
                          <span>{(data.discount == 100 ? "Free" : `-${data.discount}%`)}</span>
                          <span>{(data.discount == 100 ? " " : "Off")}</span>
                        </>
                      }
                    </div>
                  </Link>
                </div>
                <div className="rbt-card-body">
                  <ul className="rbt-meta">
                    <li>
                      <i className="feather-book"></i>
                      {data.lesson} Lessons
                    </li>
                    {(data.student != 0) &&
                      <li>
                        <i className="feather-users"></i>
                        {data.student} Students
                      </li>}
                    <li>
                      <i className="feather-users"></i>
                      {data.duration}
                    </li>
                  </ul>
                  <h4 className="rbt-card-title">
                    {/* {data.title} */}
                    <Link href={`/course/${data.content_id}`}>
                      {data.title}
                    </Link>
                  </h4>
                  <p className="rbt-card-text">{data.description.long.substring(0, 100)}...</p>
                  <div className="rbt-review">
                    <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <span className="rating-count">
                      ({data.review} Reviews)
                    </span>
                  </div>
                  <div className="rbt-card-bottom">
                    <PriceLabel post={data} />
                    {/* {(data.price != 0) &&
                      <div className="rbt-price">
                        <span className="current-price">${data.price}</span>
                        <span className="off-price">${data.offPrice}</span>
                      </div>
                    }
                    {(data.price == 0) &&
                      <div className="rbt-price">
                        <span className="current-price">FREE</span>
                      </div>
                    } */}
                    <Link
                      className="rbt-btn-link"
                      href={`/course/${data.content_id}`}
                    >
                      Learn More
                      <i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        <div className="rbt-swiper-pagination"></div>
      </Swiper>
    </>
  );
};

export default HomeCourses;
